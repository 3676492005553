




































import { Vue, Component } from 'vue-property-decorator'
import { namespace, Action } from 'vuex-class' 

const thirdPartyAppManagement = namespace('thirdPartyAppManagement')
@Component
export default class QRCodes extends Vue {

	private loading = true

	@thirdPartyAppManagement.State
	private information!: any

	@thirdPartyAppManagement.Action
	public fetchQuotaAndSpeedUp!: () => Promise<any>

	async mounted () {
		await this.fetchQuotaAndSpeedUp()
		this.loading = false
	}
}
